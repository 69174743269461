<template>
  <ContentCard v-if="!edit" :contentObject="getDisplayContent" :onClick="disableEdit ? undefined : () => edit = true"/>
  <div v-else>
    <EditComponent
      class="exporo-app-edit-view"
      v-bind:limitedFields="limitedFields"
      v-bind:title="$t('metaData.fields.naturalUser.address.label')"
      v-slot="{ naturalUser, errors, checkIfExists, niceErrorMessage, onSave, onCancel, nextBtnClicked }"
      :stateCallback="handleEditState"
    >
      <AutoCompleteComponent
        v-model="naturalUser.taxableCountry"
        :label="`${$t('metaData.fields.naturalUser.taxableCountry.label')}*`"
        :placeholder="`${$t('metaData.fields.naturalUser.taxableCountry.label')}`"
        :optionsList="countriesOptions"
        :error="
          checkIfExists('errors.naturalUser.taxableCountry') ? niceErrorMessage(errors.naturalUser.taxableCountry) : ''
        "
        :nextBtnClicked="nextBtnClicked"
        valueKey="code"
        valueLabel="country"
        :filled="true"
        :clearable="false"
      />
      <InputComponent
        v-model="naturalUser.taxId"
        :label="$t('metaData.fields.naturalUser.taxId.label')"
        :placeholder="$t('metaData.fields.naturalUser.taxId.placeholder')"
        :error="checkIfExists('errors.naturalUser.taxId') ? niceErrorMessage(errors.naturalUser.taxId) : ''"
        :nextBtnClicked="nextBtnClicked"
        inputType="text"
        :tooltip="disableTooltip ? null : $t('metaData.fields.naturalUser.taxId.tooltip')"
        :filled="true"
      />
      <FormButtons :onSave="onSave" :onCancel="onCancel" />
    </EditComponent>
  </div>
</template>

<script>
import EditComponent from '@/components/UserMetadata/EditComponent.vue';
import InputComponent from '@/components/reusable/Input/Input.vue';
import AutoCompleteComponent from '@/components/reusable/Select/AutoComplete.vue';
import FormButtons from '@/components/ExporoApp/reusable/FormButtons.vue';
import ContentCard from '@/components/ExporoApp/reusable/ContentCard.vue';
import { countryOptions } from '@/enum/countries';

export default {
  name: 'TaxInfoCard',
  data() {
    const defaultCountries = ['DE', 'AT', 'NL', 'CH', 'FR', 'IT', 'DK'];

    const countriesOptions = countryOptions()
      .filter((item) => {
        return !defaultCountries.includes(item.code);
      })
      .map((nationality) => ({
        value: nationality.code,
        text: nationality.country,
      }));

    const countriesOptionsDefault = defaultCountries.map((item) => {
      const country = countryOptions().find((countryObj) => {
        return countryObj.code == item;
      });

      return {
        value: country.code,
        text: country.country,
      };
    });

    return {
      countriesOptions: [...countriesOptionsDefault, ...countriesOptions],
      edit: false,
    };
  },
  methods: {
    handleEditState(loadingState, errors) {
      this.loading = loadingState;
      if(errors.length === 0 && !this.loading) {
        this.edit = false;
      }
    },
  },
  computed: {
    getDisplayContent() {
      return {
        taxableCountry: {
          label: this.$t('metaData.fields.naturalUser.taxableCountry.label'),
          value: this.$store.state.naturalUser.taxableCountry
            ? this.countriesOptions.filter(opt => opt.value === this.$store.state.naturalUser.taxableCountry)[0].text 
            : null,
          placeholder: this.$t('metaData.fields.naturalUser.taxableCountry.noValue'),
        },
        taxId: {
          label: this.$t('metaData.fields.naturalUser.taxId.label'),
          value: this.$store.state.naturalUser.taxId,
          placeholder: this.$t('metaData.fields.naturalUser.taxId.noValue'),
        },
      };
    },
    getIsUSTaxed: {
      get(value) {
        return !value.$children[0].naturalUser.hasOwnProperty('isUSTaxed')
          ? false
          : !value.$children[0].naturalUser.isUSTaxed;
      },
      set(value) {
        this.$children[0].errors = null;
        this.$children[0].naturalUser.isUSTaxed = !value;
      },
    },
  },
  props: {
    limitedFields: {
      type: String,
      default: 'rewardsInformation',
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    disableTooltip: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ContentCard,
    FormButtons,
    EditComponent,
    InputComponent,
    AutoCompleteComponent,
  },
};
</script>

<style scoped lang="scss">

</style>

