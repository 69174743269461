<template>
  <div class="profile-webview mb-12 pa-4 pb-12" v-if="!loading">
    <div class="section">
      <div class="section-label">
        {{ taxesInformation.label }}
      </div>
      <TaxInfoCard />
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { mapGetters } from 'vuex';
import TaxInfoCard from '@/components/ExporoApp/TaxInfoCard.vue';

export default {
  components: {
    TaxInfoCard,
  },
  data() {
    return {
      naturalUser: {},
      loading: true,
      taxesInformation: {
        label: this.$t('taxes.title'),
        edit: false,
        loading: false,
      },
    };
  },
  async mounted() {
    this.$store.commit('setLoaded', { loaded: true });

    this.loading = false;
  },

  computed: {
    ...mapGetters(['isIdentified']),
  },
};
</script>

<style scoped lang="scss">
@import '@/style/media';

.profile-webview {
  display: flex;
  flex-direction: column;
  align-items: center;
  .v-card {
    width: 480px;
    height: 100% !important;
    z-index: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 32px 0px #170E250D, 0px 0px 4px 0px #170E250A;
  }
}
.section {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: -webkit-fill-available;
}
.section-label {
  color: #717E8E;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  padding-bottom: 22px;
  @include media('<sm') {
    padding-bottom: 18px;
  }
}
</style>        