var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.edit)?_c('ContentCard',{attrs:{"contentObject":_vm.getDisplayContent,"onClick":_vm.disableEdit ? undefined : function () { return _vm.edit = true; }}}):_c('div',[_c('EditComponent',{staticClass:"exporo-app-edit-view",attrs:{"limitedFields":_vm.limitedFields,"title":_vm.$t('metaData.fields.naturalUser.address.label'),"stateCallback":_vm.handleEditState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var naturalUser = ref.naturalUser;
var errors = ref.errors;
var checkIfExists = ref.checkIfExists;
var niceErrorMessage = ref.niceErrorMessage;
var onSave = ref.onSave;
var onCancel = ref.onCancel;
var nextBtnClicked = ref.nextBtnClicked;
return [_c('AutoCompleteComponent',{attrs:{"label":((_vm.$t('metaData.fields.naturalUser.taxableCountry.label')) + "*"),"placeholder":("" + (_vm.$t('metaData.fields.naturalUser.taxableCountry.label'))),"optionsList":_vm.countriesOptions,"error":checkIfExists('errors.naturalUser.taxableCountry') ? niceErrorMessage(errors.naturalUser.taxableCountry) : '',"nextBtnClicked":nextBtnClicked,"valueKey":"code","valueLabel":"country","filled":true,"clearable":false},model:{value:(naturalUser.taxableCountry),callback:function ($$v) {_vm.$set(naturalUser, "taxableCountry", $$v)},expression:"naturalUser.taxableCountry"}}),_c('InputComponent',{attrs:{"label":_vm.$t('metaData.fields.naturalUser.taxId.label'),"placeholder":_vm.$t('metaData.fields.naturalUser.taxId.placeholder'),"error":checkIfExists('errors.naturalUser.taxId') ? niceErrorMessage(errors.naturalUser.taxId) : '',"nextBtnClicked":nextBtnClicked,"inputType":"text","tooltip":_vm.disableTooltip ? null : _vm.$t('metaData.fields.naturalUser.taxId.tooltip'),"filled":true},model:{value:(naturalUser.taxId),callback:function ($$v) {_vm.$set(naturalUser, "taxId", $$v)},expression:"naturalUser.taxId"}}),_c('FormButtons',{attrs:{"onSave":onSave,"onCancel":onCancel}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }